import styles from "./ComingSoon.module.css";

const ComingSoon = () => {
  return (
    <div className={styles.container}>
      <p className={styles.containerText}>Coming Soon</p>
    </div>
  );
};
export default ComingSoon;
