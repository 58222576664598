import styles from "./Header.module.css";
import logo from "../../assets/images/full-logo-transparent-1950x1142.png";

const Header = () => {
  return (
    <div className={styles.container}>
      <div>
        <img className={styles.logo} src={logo} />
      </div>
    </div>
  );
};

export default Header;
