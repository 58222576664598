import styles from "./Home.module.css";
import Header from "../../components/header/Header";
import ComingSoon from "../../components/comingsoon/ComingSoon";
import Footer from "../../components/footer/Footer";
const Home = () => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <ComingSoon />
        <Header />
      </div>
      <div className={styles.info}>
        <p className={styles.infoText}>
          BillyQR is an app (available on iOS and Android devices) that manages
          assets by allowing users to collect, store and share service records
          for equipment. Through the unique QR code generated by the BillyQR
          platform, equipment owners and maintenance providers can retrieve
          archived service visits for a specific piece of equipment. Service
          providers can create new maintenance records though the app and be
          alerted when a service visit is required though the scheduling
          feature. Manufacturers can also monitor service visits on the
          equipment they sell. The unique BillyQR code can either be adhered to
          the equipment by the manufacturer or it can be generated and placed on
          the equipment by the owner or service provider.
        </p>
      </div>
    </div>
  );
};
export default Home;
